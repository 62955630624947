@use "@angular/material" as mat;

.menu {
  &-profile {
    border-radius: 8px 0 8px 8px !important;
    border: solid 1px mat.m2-get-color-from-palette($secondary-map, 600);
    box-shadow: none !important;

    .mat-mdc-menu-content {
      padding: 19px 13px !important;
    }

    .mat-mdc-menu-item {
      min-height: fit-content !important;
      padding: 0 !important;

      mat-icon {
        line-height: 16.8px;
      }

      &:not(:last-child) {
        margin-bottom: 8px !important;
      }

      &:hover {
        background: transparent;
      }
    }
  }
}

.mat-mdc-menu-item:hover,
.mat-mdc-menu-item.cdk-program-focused,
.mat-mdc-menu-item.cdk-keyboard-focused,
.mat-mdc-menu-item-highlighted {
  &:not([disabled]) {
    background: #eaeaea;
  }
}

.mat-mdc-menu-item:active,
.mat-mdc-menu-item.cdk-program-focused,
.mat-mdc-menu-item.cdk-keyboard-focused,
.mat-mdc-menu-item-highlighted {
  &:not([disabled]) {
    background: #b5b5b5;
  }
}

.mat-mdc-menu-item:focus,
.mat-mdc-menu-item.cdk-program-focused,
.mat-mdc-menu-item.cdk-keyboard-focused,
.mat-mdc-menu-item-highlighted {
  &:not([disabled]) {
    background: #eaeaea;
  }
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: rgb(255 255 255 / 0%);
  --mat-mdc-button-ripple-color: rgb(255 255 255 / 0%);
}
