.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: $color-white !important;
  background-color: $color-white !important;
  --mdc-plain-tooltip-supporting-text-color: $color-tooltip !important;
  color: $color-tooltip !important;
  box-shadow: 0px 1px 3px 0px #00000040 !important;
  border-radius: 4px;
  gap: 8px;
  font-size: 12px;
 margin:0px !important;
}

.mat-mdc-tooltip-panel{
  // position: relative;
  &::after {
    width: 12px;
    height: 12px;
    content: '';
    position: absolute;
    z-index: 1000000;
    background-color: #ffffff;
  }
  &.mat-mdc-tooltip-panel-below {
    overflow: initial;
    margin-top: 0.6rem;
    &::after {
      top: 0px;
      right: calc(50% - 0.5rem);
      transform: translatey(-7px) rotate(45deg);
       border: 1px solid #0000001c;
       border-bottom: none;
       border-right: none;
    }
  }
  &.mat-mdc-tooltip-panel-above {
    overflow: initial;
    margin-bottom: 0.6rem;
    &::after {
      top: 100%;
      right: calc(50% - 0.5rem);
      transform: translatey(-7px) rotate(225deg);
       border: 1px solid #0000001c;
       border-bottom: none;
       border-right: none;
    }
  }
  &.mat-mdc-tooltip-panel-left {
    overflow: initial;
    margin-right: 0.6rem;
    &::after {
      right: -8px;
      top: calc(50% - 0.5rem);
      transform: translatey(0px) rotate(135deg);
      border: 1px solid #0000001c;
      border-bottom: none;
      border-right: none;
    }
  }
  &.mat-mdc-tooltip-panel-right {
    overflow: initial;
    margin-left: 0.6rem;
    &::after {
      left: -8px;
      top: calc(50% - 0.5rem);
      transform: translatey(0px) rotate(315deg);
      border: 1px solid #0000001c;
      border-bottom: none;
      border-right: none;
    }
  }
}

.tooltip__surface {
  padding: 12px !important;
}
