@use "@angular/material" as mat;

ngx-skeleton-loader.mt {
  .loader:first-of-type {
    margin-top: 24px;
  }
}

.page_loader {
  @include flex-row(center);

  justify-content: center;
  z-index: 9999;

  .circle {
    width: 16px !important;
    height: 16px !important;
    background: mat.m2-get-color-from-palette($primary-map, 500) !important;
  }
}
