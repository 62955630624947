@use "@angular/material" as mat;

mat-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .mat-expansion-panel {
    width: 100%;

    * {
      transition: none !important;
    }

    background: transparent !important;
    box-shadow: none !important;

    &-header {
      background: transparent !important;
      box-shadow: none !important;
      padding: 5px;

      &-title {
        @include flex-row(center);

        justify-content: space-between;
      }
    }

    &-body {
      padding: 0;
    }
  }

  &.radio-button-list {
    .mat-mdc-radio {
      &-button {
        display: block;
        width: 100%;
        border: 1px solid $color-radio-btn-border;
        background-color: $color-white;
        border-radius: $radio-btn-border-radius;
        margin-top: 16px;
        box-sizing: border-box;
        padding: 16px;

        &.disabled {
          opacity: 0.5;
        }

        .mdc-form-field {
          width: 100% !important;

          label {
            width: 100% !important;
          }
        }

        .user-account-radio-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .mat-mdc-icon-button.mat-mdc-button-base {
            padding: 0 !important;
          }

          .user-account-label {
            display: flex;
            flex-direction: column;
          }

          .user-account-display-name {
            font-size: 16px;
            font-weight: 600;
            color: $color-black;
            overflow: visible !important;
            white-space: normal !important;
          }

          .user-account-logo {
            width: 50px;
            display: flex;

            // justify-content: center;
            align-items: center;
            flex: 0 0 50px;
          }

          .user-account-type {
            color: $color-input-label;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            overflow: visible !important;
            white-space: normal !important;
          }
        }
      }

      .mdc-radio {
        &__background,
        &__inner-circle,
        &__outer-circle {
          width: 24px;
          height: 24px;
        }
      }

      &-checked {
        border-color: mat.m2-get-color-from-palette($secondary-alternate-map, 300);
        background-color: mat.m2-get-color-from-palette($secondary-alternate-map, 400);
        color: mat.m2-get-color-from-palette($secondary-alternate-map, contrast(400));

        .mdc-radio__outer-circle {
          border-color: mat.m2-get-color-from-palette($secondary-alternate-map, 300) !important;
          background-color: mat.m2-get-color-from-palette($secondary-alternate-map, 300);

          // border-color: $color-primary !important;
        }
      }
    }
  }
}
