@mixin flex-column($alignItem) {
  align-items: $alignItem!important;
  display: flex !important;
  flex-direction: column!important;
}

@mixin flex-row($alignItem) {
  align-items: $alignItem!important;
  display: flex!important;
  flex-direction: row!important;
}

@mixin flex-inline($alignItem) {
  align-items: $alignItem!important;
  display: inline-flex!important;
  flex-direction: row!important;
}
