@use "@angular/material" as mat;
@import "./variables";
@import "mixins/flex";
@import "./theme/palletes";
@import "@angular/material";

.header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: $color-white !important;

  @include flex-column(center);

  pbb-header {
    @include responsive-width;
    &.responsive-large {
      @include responsive-width(true);
    }
    overflow: hidden;

    .header {
      min-height: auto;

      &-row {
        @include responsive-width;
        &.responsive-large {
          @include responsive-width(true);
        }
        justify-content: space-between;
        height: $header-height;
        min-height: $header-height !important;
      }

      &-left {
        @include flex-row(center);

        &-title {
          @include flex-column(flex-start);

          justify-content: left;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &-right {
        &-chip {
          @include flex-row(center);

          justify-content: center;
          min-width: 85px;
          height: 25px;
          border-radius: 20px;
          line-height: 100%;

          svg {
            height: 13.33px;
            width: 10.67px;
            padding-right: 6px;
          }
        }
      }

      &-progress-bar {
        @include responsive-width;

        padding: 0;
        height: 4px;
      }
    }
  }

  @media only screen and (max-width: 575px) {
    pbb-header {
      .header-left-title span {
        max-width: 216px;
      }
    }
  }
}
