@use "@angular/material" as mat;

// ------component.css
.spinner-container {
  display: inline-block;
  position: relative;

  .mat-mdc-progress-spinner circle {
    stroke: mat.m2-get-color-from-palette($primary-map, 500);
    stroke-width: 2px !important;
  }

  &-image-icon {
    img,
    mat-icon {
      position: absolute;
      width: 66px !important;
      height: 66px !important;
      top: 36px;
      left: 36px;
      font-size: 66px !important;
    }

    svg {
      position: absolute;
      width: 110px !important;
      height: 110px !important;
      top: 20px;
      left: 20px;
    }
  }
}
