@use "@angular/material" as mat;

.mat-mdc-icon-button {
  height: max-content;
  width: max-content;
  color: $color-black !important;

  @include margin(right, 7px);

  .mdc-button__label {
    line-height: normal;
  }

  &[disabled] {
    color: $color-grey-active !important;
  }

  &:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 1;
    background-color: $color-grey-hover;
  }

  &:focus .mat-mdc-button-persistent-ripple::before {
    opacity: 1;
    background-color: $color-grey-hover;
  }

  &:active .mat-mdc-button-persistent-ripple::before {
    opacity: 1;
    background-color: $color-grey-active;
  }
}

.mdc-button {
  width: 100%;
}

.mdc-button__label {
  font-weight: 600 !important;
}

button {
  &:focus-visible {
    border: solid black;
  }

  &.underline {
    text-decoration: underline;
  }

  &.btn-ivs {
    background-color: #2b2b2b !important;
    color: #fff !important;
    border-radius: 10px !important;
    text-transform: uppercase;

    .mdc-button__label {
      @include flex-row(center);

      justify-content: flex-start;
    }
  }

  &.btn-fi {
    height: 60px !important;
    padding: 12px 16px;
    border: 1px solid mat.m2-get-color-from-palette($secondary-map, 600) !important;
    background: transparent;
    justify-content: flex-start;

    .mdc-button__label {
      @include flex-row(center);

      justify-content: flex-start;
    }

    svg,
    img {
      margin-right: 10px;
      width: $icon-img;
      height: $icon-img;
    }

    &:disabled {
      background: $color-fi-btn-disabled;
      border-color: $color-fi-btn-disabled !important;
      opacity: 0.6;
    }
  }

  &.btn-stroked-no-border {
    border: none;
    background-color: transparent !important;
  }

  &.btn-w-icon {
    &-append {
      pbb-svg-icon {
        margin-left: 12px;
      }
    }

    &-prepend {
      pbb-svg-icon {
        margin-right: 12px;
      }
    }
  }

  &.btn-signin,
  &.btn-signout,
  &.btn-login {
    margin: 0;
    padding: 8px;
    height: auto !important;
    width: max-content;
    border-radius: 7px !important;
    display: inline-flex;
  }

  &.btn-card-action {
    @include flex-row(center);

    max-width: 65px;
    height: auto !important;
    border: 1px solid #000 !important;
    border-radius: 8px !important;
    padding: 6px 16px;
    color: black !important;
    font-size: 14px !important;
    font-weight: 500 !important;

    .mdc-button__label {
      display: flex;
    }

    &:focus-visible {
      outline: 2px solid black;
    }
  }

  &.btn-slim {
    height: fit-content !important;
  }

  &.btn-with-spinner {
    display: flex;

    mat-progress-spinner {
      right: 24px;
    }
  }

  &.btn-add-address {
    background-color: mat.m2-get-color-from-palette($secondary-alternate-map, 400) !important;
    color: mat.m2-get-color-from-palette($secondary-alternate-map, contrast(400)) !important;
    width: 100% !important;
    height: fit-content !important;
    border-radius: 8px !important;
    padding: 12px;

    @include flex-row(center);

    justify-content: space-between;

    mat-label {
      @include flex-row(center);

      width: inherit;
      justify-content: space-between;
    }

    .spacer {
      flex: 1 1 auto;
    }

    mat-icon {
      width: 24px !important;
      height: 24px !important;
      font-size: x-large !important;
    }
  }

  &.btn-icon-slim {
    width: fit-content !important;
    height: fit-content !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  &.btn-address-toggle {
    padding: 0;
    width: fit-content;
    height: fit-content !important;
    float: right;
    box-shadow: none;
    border: none;
    position: absolute;
    top: -24px;
    right: 0;
  }

  &.btn-header-logo {
    width: fit-content;
    padding: 0;

    /* TODO(mdc-migration): The following rule targets internal classes of
    button that may no longer apply for the MDC version. */
    .mat-button-focus-overlay {
      background-color: transparent;
    }
  }

  &.btn-skip-splash {
    width: 57px;
    border: none;
  }

  &.btn-fab {
    border-radius: 50% !important;
    box-shadow: none !important;
    width: 28px;
    height: 28px !important;
  }

  &.btn-no-hover {
    .mdc-button__ripple::before {
      background-color: transparent;
    }
  }

  &.btn-tooltip {
    width: 25px !important;
    height: 25px !important;
    border: none;
    background-color: transparent;
    padding: 0;
  }
}

.mat-mdc-button-base {
  border-radius: $button-border-radius !important;
  line-height: 100% !important;
  width: 100%;
  box-sizing: border-box;

  @include flex-row(center);

  height: 46px !important;
  justify-content: center;
}

.mat-mdc-button-touch-target {
  height: 46px !important;
}

@media (hover: hover) {
  button.mat-mdc-unelevated-button.mat-primary:hover {
    background-color: mat.m2-get-color-from-palette($primary-map, 600);
  }

  button.mat-mdc-unelevated-button.mat-accent:hover {
    background-color: mat.m2-get-color-from-palette($primary-map, 300);
  }
}

button.mat-mdc-unelevated-button.mat-primary:active {
  background-color: mat.m2-get-color-from-palette($primary-map, 700);
}

button.mat-mdc-unelevated-button.mat-primary:disabled {
  background-color: mat.m2-get-color-from-palette($secondary-map, 500) !important;
  opacity: 0.6;
  color: $color-dark-charcoal !important;
}

button.mat-mdc-unelevated-button.mat-accent {
  color: mat.m2-get-color-from-palette($primary-map, 500);
  background-color: transparent;
}

button.mat-mdc-unelevated-button.mat-accent:active {
  background-color: mat.m2-get-color-from-palette($primary-map, 400);
}

button.mat-mdc-unelevated-button.mat-accent:disabled {
  background-color: $color-white !important;
  opacity: 0.4;
}

/// Secondary alternate
button.mat-mdc-outlined-button.mat-secondary-alternate {
  color: mat.m2-get-color-from-palette($secondary-alternate-map, 500);
  border-color: mat.m2-get-color-from-palette($secondary-alternate-map, 500);
}

button,
a {
  &.mat-mdc-unelevated-button.mat-secondary {
    background-color: mat.m2-get-color-from-palette($secondary-alternate-map, 500) !important;
    color: mat.m2-get-color-from-palette($secondary-map, 50) !important;
  }
}

button.mat-mdc-unelevated-button.mat-secondary:disabled {
  background-color: mat.m2-get-color-from-palette($secondary-map, 500) !important;
  opacity: 0.6;
  color: $color-dark-charcoal !important;
}

button.mat-mdc-unelevated-button.mat-secondary:hover {
  background-color: mat.m2-get-color-from-palette($secondary-alternate-map, 200) !important;
  color: mat.m2-get-color-from-palette($secondary-map, 50) !important;
}

button.mat-mdc-unelevated-button.mat-secondary.cdk-focused {
  background-color: mat.m2-get-color-from-palette($secondary-alternate-map, 600) !important;
  color: mat.m2-get-color-from-palette($secondary-map, 50) !important;
}

.btn-webauthn-signin .mdc-button__label {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.show-more-btn:focus {
  opacity: 1;

  .mat-mdc-focus-indicator {
    background-color: $color-warn-light;
  }
}

// 300: #3148BB, // radio border color
// 400: #edf0fe, // btn hover
// 500: #4d69f5, // btn default
// 600: #1b2a79, // btn pressed

// button.mat-stroked-button.mat-secondary-alternate:hover {
//   background-color:  mat.get-contrast-color-from-palette($secondary-alternate-map, 600);
// }

// button.mat-stroked-button.mat-secondary-alternate.cdk-focused {
//   background-color:  mat.get-contrast-color-from-palette($secondary-alternate-map, 700);
// }
