@use "@angular/material" as mat;

.pbb-chip {
  background-color: mat.m2-get-color-from-palette($secondary-map, 200);
  box-shadow: none;
  border-radius: 40px;
  height: 40px;

  @include flex-inline(center);

  box-sizing: border-box;
  padding: 0 8px;

  pbb-svg-icon {
    padding-right: 8px;
  }

  &.full-width {
    width: 100%;
    border-radius: 12px;
    height: 48px;
    padding: 0 16px;

    strong {
      padding-left: 8px;
    }
  }
}

mat-chip-listbox {
  .mdc-evolution-chip-set__chips {
    padding-top: 4px;
    margin-left: 0 !important;
  }

  .mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
    display: flex;
    align-items: center;
  }
}

.header-right {
  .mdc-evolution-chip__action--presentational {
    cursor: pointer;
  }
}

mat-chip,
mat-basic-chip {
  height: 20px !important;

  @include flex-row(center);

  justify-content: center;
  width: fit-content;

  .mat-mdc-chip-action {
    padding: 0 !important;
  }

  .mat-mdc-chip-action-label {
    display: flex;
    align-items: center;
  }

  &.mat-mdc-chip.mat-mdc-standard-chip.mat-primary-alternate {
    background-color: mat.m2-get-color-from-palette($primary-alternate-map, 400);
    color: mat.m2-get-contrast-color-from-palette($primary-alternate-map, 400);
  }

  &.default-chip {
    padding: 0 8px;
    border-radius: 44px;
    height: 20px;

    mat-icon {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      height: 20px;
      width: 20px;
    }
  }

  &.primary-chip {
    background-color: $color-info-bg;
    color: $color-info;
  }

  &.alert-chip {
    background-color: $color-warn-bg-2;
    color: $color-warn-2;
  }

  &.device-chip {
    padding: 4px 8px;
    border-radius: 44px;
    background-color: $color-info-bg;
    gap: 4px;
  }

  &.set-default-chip {
    padding: 0 8px;
    border-radius: 44px;
    height: 20px;
    box-shadow: 0 0 1px mat.m2-get-color-from-palette($secondary-alternate-map, 300);
    background-color: mat.m2-get-color-from-palette($secondary-map, 700);
    color: mat.m2-get-color-from-palette($secondary-alternate-map, 300);

    mat-icon {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      height: 20px;
      width: 20px;
    }
  }

  &.new-account-chip {
    margin-left: 16px;
    padding: 4px 8px;
    background-color: $color-fushsia; // mat.get-color-from-palette($primary-map, 400);
    border-radius: 4px;
  }

  &.oidc-chip {
    padding: 12px 8px;
    border-radius: 40px;
    background-color: mat.m2-get-color-from-palette($secondary-map, 500);
  }

  &.shopping-cart-chip {
    margin: 0 !important;
    border-radius: 20px;
    box-shadow: 0 2px 2px 0 rgb(0 83 185 / 25%);
    min-height: auto;
    height: auto;
    cursor: pointer;
    padding: 4px 8px;

    mat-icon {
      line-height: 15px !important;
    }
  }

  &.shopping-cart-chip-no-pointer {
    margin: 0 !important;
    border-radius: 20px;
    box-shadow: 0 2px 2px 0 rgb(0 83 185 / 25%);
    min-height: auto;
    height: auto;
    padding: 4px 8px;

    mat-icon {
      line-height: 15px !important;
    }
  }
}

.mat-mdc-chip.mat-primary-alternate:hover {
  background-color: mat.m2-get-color-from-palette($primary-alternate-map, 500);
  color: mat.m2-get-contrast-color-from-palette($primary-alternate-map, 500);
}

.mat-mdc-chip.mat-primary-alternate.cdk-focused {
  background-color: mat.m2-get-color-from-palette($primary-alternate-map, 600);
  color: mat.m2-get-contrast-color-from-palette($primary-alternate-map, 600);
}
