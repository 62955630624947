@font-face {
  font-family: MaterialIcons-Outlined;
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/MaterialIcons-Outlined.eot"); /* For IE6-8 */
  src:
    local("MaterialIcons-Outlined"),
    url("../assets/fonts/MaterialIcons-Outlined.woff2") format("woff2"),
    url("../assets/fonts/MaterialIcons-Outlined.ttf") format("truetype");
}

.material-icons {
  font-family: MaterialIcons-Outlined, monospace;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;

  &-outlined {
    font-family: MaterialIcons-Outlined, monospace;
  }
}
