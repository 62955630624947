@use "@angular/material" as mat;

.card {
  &_row {
    mat-card {
      border-width: 0;
      border-style: none;
      border-color: transparent;
      border-radius: 12px;

      @include flex-row(center);

      justify-content: space-between;
      padding: 16px;

      .card_row-left {
        @include flex-row(center);

        line-height: 100%;
      }

      svg {
        margin-right: 10px;

        @include margin(right, 10px);
        @include margin(left, 4px);
      }
    }
  }

  &_column {
    > mat-card {
      border-radius: 12px;

      @include flex-column(center);

      padding: 16px;
    }
  }

  &_no-border {
    > mat-card {
      border: none;

      // background: mat.get-color-from-palette($secondary-map, 500);
    }
  }

  &_grey {
    > mat-card {
      background: mat.m2-get-color-from-palette($secondary-map, 500);
    }
  }

  &_normal {
    > mat-card {
      @include flex-column(normal);

      border-radius: 12px;
      background: mat.m2-get-color-from-palette($secondary-map, 50);
      border: 1px solid #0000001f;
    }
  }

  &_border {
    > mat-card.mat-mdc-card-outlined {
      @include flex-column(normal);

      border-radius: 8px !important;
      border: 1px solid #f4f4f4 !important;
      background: mat.m2-get-color-from-palette($secondary-map, 50) !important;
      padding: 12px 16px;
    }
  }

  &_content {
    &_row {
      @include flex-row(center);

      justify-content: space-between;
    }
  }

  mat-card .subtitle-2 {
    margin-bottom: 16px !important;
  }

  .mat-mdc-card-content {
    padding: 0 !important;

    .mat-body-1 {
      padding: 0 !important;
      margin: 2px 0 !important;
    }
  }

  &_invalid {
    > mat-card {
      border: 1px solid #ca0000 !important;
      box-sizing: border-box !important;
      border-radius: 12px !important;
    }
  }

  &_invalid_bg {
    > mat-card {
      border: 1px solid #ca0000 !important;
      box-sizing: border-box !important;
      border-radius: 12px !important;
      background: #fddfdf !important;
    }
  }
}

.cart-review-container {
  .mdc-dialog__content {
    overflow-y: scroll !important;
  }

  mat-dialog-content.mat-mdc-dialog-content {
    padding: 0 !important;
  }

  pbb-card {
    border-radius: 8px;
    box-shadow: 0 0 1px 0 rgb(12 26 75 / 24%);
    display: block;
    margin: 0 24px;
    margin-bottom: 16px;
  }

  &__title-container {
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;

    &--title {
      flex: 1;

      .mat-subtitle-2 {
        font-size: 14px;
        line-height: 120%;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        color: mat.m2-get-color-from-palette($primary-map, 500);
        margin-bottom: 4px;
      }

      .mat-body-2 {
        color: $color-black;
      }

      .mdc-icon-button {
        height: 24px !important;
        width: 24px;
      }
    }
  }

  &__order-items-container {
    margin-bottom: 16px;

    mat-card-title.mat-subtitle-2 {
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    mat-card-subtitle.mat-body-2 {
      color: $color-black;
    }

    .cart-review-items {
      .cart-review-item {
        margin: 16px 0;

        h3.mat-body-1 {
          margin-bottom: 8px !important;
          font-size: 16px;
        }

        .cart-review-item-quantity {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;

          &--subscription {
            color: mat.m2-get-color-from-palette($primary-map, 500);
            font-weight: 400;
          }

          .mat-body-2 {
            font-size: 14px;
            color: $color-black-light;
            font-weight: 600;

            .vertical-divider {
              border-left: 2px solid #f2f2f2;
              margin-left: 6px;
              padding-left: 6px;
            }
          }
        }

        .cart-review-item-recurring-info {
          width: 252px;
          color: $color-black-light;
          margin-bottom: 8px;

          span.start-date {
            color: mat.m2-get-color-from-palette($primary-map, 500);
          }
        }

        .cart-review-item-description {
          width: 252px;
          color: $color-black-light;
        }
      }
    }
  }

  pbb-card.fixed-height mat-card {
    height: 400px;
    overflow-y: scroll;
    scrollbar-width: 8px !important; /* Firefox */
    -ms-overflow-style: auto !important; /* IE 10+ */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e8e8e8;
      border-radius: 10px;
      background: #e8e8e8;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #7a7a7a;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #7a7a7a;
    }
  }

  .card.card_column {
    > mat-card {
      padding: 24px 24px !important;
    }
  }

  pbb-total-amount-card {
    h3.mat-subtitle-2 {
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: mat.m2-get-color-from-palette($primary-map, 500);
    }

    mat-card-subtitle.subtitle-2 {
      color: #1f1f1f;
    }

    span.mat-body-1 {
      color: $color-dark-charcoal;
    }

    span.subtitle-1 {
      color: #1f1f1f;
    }
  }
}
