@mixin respond($breakpoint) {
  // $breakpoint is simply a variable that can have several values

  @if $breakpoint == tablet {
    @media only screen and (max-width: $breakpoint-tablet) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: $breakpoint-mobile) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media only screen and (min-width: $breakpoint-large) {
      @content;
    }
  }
}

@mixin responsive-width($large: false) {
  width: $content-width-lg;
  min-width: $content-width-lg !important;
  margin: 0 auto;

  @include respond(tablet) {
    width: $content-width-md;
    min-width: $content-width-md !important;
    margin: 0 auto;
  }

  @include respond(mobile) {
    width: $content-width-sm;
    min-width: $content-width-sm !important;
    margin: 0 auto;
  }

  @if $large {
    @include respond(lg) {
      width: $breakpoint-large;
      min-width: $breakpoint-large !important;
      margin: 0 auto;
    }
  }
}
