@use "@angular/material" as mat;
@import "./variables";

footer {
  margin-top: auto;
  background-color: $color-white !important;
  
  pbb-footer {
    display: block;
    padding: 24px 0px 32px;
    @include responsive-width;
    &.responsive-large {
      @include responsive-width(true);
    }
  }

  .footer-container {
    margin: 0px 16px;
  }

  .footer-link-list .footer-links {
    a:hover {
      font-weight: 700;
      color: mat.m2-get-color-from-palette($primary-map, 500);
    }

    a:active {
      font-weight: 700;
      color: mat.m2-get-color-from-palette($primary-map, 500);
    }
  }

  .footer-logo {
    align-items: center;
    justify-content: center;
    gap: 16px;
    display: flex;
  }

  .footer-logo-text {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .footer-logo-text-icon {
    height: 14px;
    padding-left: 5px;
    font-size: 14px;
    color: #ca27ca;
  }

  .footer-social-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .footer-social-title {
      white-space: nowrap;
      font-weight: 400;
      font-size: 16px;
      line-height: 19.2px;
      text-decoration: none;
    }

    .footer-social-links {
      display: flex;
      align-items: center;
      gap: 18.774px;
      align-self: stretch;

      svg {
        fill: #696969;
      }

      svg:hover {
        fill: black !important;
      }

      svg:active {
        fill: black !important;
      }
    }
  }

  .footer {
    @include flex-column(center);

    justify-content: center;

    button {
      width: auto;
    }
  }
}
