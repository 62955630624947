@use "@angular/material" as mat;
@import "./mixins/flex";
@import "./mixins/margin";
@import "./mixins/responsive";
@import "./variables";
@import "./theme/palletes";

body {
  margin: 0;
  background-color: $color-body-bg;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

// sup script
sup {
  font-size: 0.6em;
}

button {
  sup {
    margin-right: 4px;
    margin-top: -6px;
  }
}

img {
  &.center-img {
    display: block;
    margin: auto;
  }
}

pbb-checkout,
pbb-onboarding-root,
pbb-auth-root,
pbb-payment-method-page,
pbb-view-address-page {
  width: 100%;
  height: 100%;
}

pbb-user-portal-root,
pbb-checkout-root {
  height: 100%;
}

pbb-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.full-height {
  height: 100%;
}
.full-content {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  @include responsive-width;

  &.responsive-large {
    @include responsive-width(true);
  }

  flex: 1;

  &_main {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .error-container {
      padding: 0 16px;
    }

    &-drawer {
      @include flex-column(center);

      &::before {
        display: none !important;
      }

      .cdk-dialog-container::before {
        display: block;
        content: "";
        position: relative;
        height: 4px;
        width: 45px;
        margin: 0 auto;
        border-radius: 15px;
        background: #c4c4c4;
        margin-bottom: 5px !important;
      }

      >* {
        width: 100%;
      }

      .mat-bottom-sheet-container {
        padding: 16px 32px 32px !important;
        background-color: mat.m2-get-color-from-palette($primary-map, contrast(50));
        border-radius: 25px 25px 0 0 !important;
        overflow: scroll;
        max-height: max-content;

        @media only screen and (max-width: 767px) {
          width: 100%;
        }

        .confirm-icon {
          color: #1f1f1f;
          font-weight: 600;
        }

        .confirm-title {
          color: #1f1f1f;
          font-size: 16px;
          line-height: 120%;
          font-weight: 600;
        }

        .confirm-subtitle {
          color: #2f2f2f;
          font-size: 14px;
          line-height: 120%;
        }
      }

      &-container {
        @include flex-column(flex-start);

        .mat-mdc-menu-item {
          height: 40px;
          line-height: 100%;
          padding: 0;
        }

        &-button-list {
          @include flex-column(center);

          align-self: stretch;
        }
      }
    }

    &-footer {
      @include flex-row(center);

      justify-content: center;
    }

    &-footer-page {
      @include flex-row(initial);

      justify-content: space-between;
      align-items: flex-start;

      &__list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: start;
        justify-content: flex-start;

        &--menu-title {
          white-space: nowrap;
          font-weight: 400;
          font-size: 16px;
          line-height: 19.2px;
          text-decoration: none;
        }
      }
    }

    &-button-list {
      @include flex-column(center);

      flex-flow: wrap;

      // button + button,
      // mat-radio-button + mat-radio-button {
      //   margin-top: 12px;
      // }
      &-title {
        @include flex-row(flex-start);
      }

      button + button {
        margin-top: 16px;
      }

      &-cookie-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-cookie-actions {
        gap: 8px;

        button {
          max-width: 170px;
        }
      }

      &-cookie-actions-mobile {
        flex-direction: column-reverse;
        display: flex;

        button {
          margin-bottom: 16px;
        }
      }

      &-onboarding-actions {
        gap: 16px;
        display: flex;
        flex-direction: column-reverse;
      }

      &-onboarding-actions-mobile {
        gap: 16px;
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &-fi-group {
      @include flex-column(normal);

      .suggestion-text {
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        .mat-icon {
          margin-right: 12px;
        }
      }
    }

    &-container {
      padding: 24px 16px;

      @include flex-column(center);

      justify-content: center;

      .person-lock-img {
        height: 145px;
        width: 145px;
      }

      .almost-finish-img {
        max-height: 220px;
      }

      >*:not(pbb-svg-icon) {
        width: 100%;
      }

      &-loader {
        @include flex-column(center);

        justify-content: center;
        z-index: 9999;
        height: 50vh;

        .circle {
          width: 16px !important;
          height: 16px !important;
          background: mat.m2-get-color-from-palette($primary-map, 500) !important;
        }
      }
    }

    &-otc {
      padding: 48px 16px !important;
    }

    &-info {
      @include flex-row(center);
    }

    &-carousel {
      // height: 327px;
      background-color: mat.m2-get-color-from-palette($primary-map, contrast(50));

      @include flex-column(center);
    }
  }

  &_overlay {
    &-full {
      @include flex-column(center);

      justify-content: center;

      .mat-mdc-dialog-container {
        @include flex-column(normal);

        width: 100vw !important;
        justify-content: center;
        height: 100vh;
        box-shadow: none;
        position: relative;
        overflow: hidden;

        .mat-mdc-dialog-content {
          height: 100vh;
          max-height: 100vh;
          scrollbar-width: none;
          padding: 0 24px !important;
          position: relative;

          img {
            max-height: 220px;
          }

          &::-webkit-scrollbar {
            display: none;
          }

          .content_main-container {
            >*:last-child {
              margin-bottom: 100px;
            }
          }
        }

        .cart-review-container .mat-mdc-dialog-content {
          max-width: 680px;
          margin: 0 auto;
          padding: 0 !important;
        }

        .dialog-title {
          font-weight: 600;
          font-size: 24px;
          letter-spacing: -0.5px;
          color: #1f1f1f;
          position: absolute;
          top: 20px;
          left: 50px;
          width: calc(100% - 80px);
        }

        .close-btn {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        .back-btn {
          position: absolute;
          top: 10px;
          left: 10px;
        }

        .redirect-spinner {
          @include flex-column(center);
          @include margin(bottom, 40px);

          justify-content: center;
        }
      }

      &-backdrop {
        width: 100%;
      }

      &.bg-grey {
        .mat-mdc-dialog-container {
          background-color: transparent !important;
        }
      }

      &.scrollable {
        .mat-mdc-dialog-container {
          overflow-y: scroll !important;
        }
      }
    }

    &-medium {
      .mat-mdc-dialog-container {
        overflow: visible;
      }
    }

    &-partial {
      @include responsive-width;
      @include flex-column(center);
      @include responsive-width;

      &.responsive-large {
        @include responsive-width(true);
      }

      justify-content: center;

      .mat-mdc-dialog-container,
      .mat-bottom-sheet-container {
        @include flex-column(normal);

        justify-content: center;
        position: relative;
        padding: 0;
        overflow: visible;

        .mdc-dialog__surface {
          border-radius: 14px;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        .mat-mdc-dialog-content {
          max-height: 100vh;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }

          .content_main-container {
            >*:last-child {
              margin-bottom: 100px;
            }
          }
        }

        .dialog-title {
          font-weight: 600;
          font-size: 24px;
          letter-spacing: -0.5px;
          color: #1f1f1f;
          width: calc(100% - 80px);
          padding-top: 24px;
        }

        .btn-full {
          border-top: solid 1px rgb(0 0 0 / 24%);
          border-radius: 0 !important;
        }

        .close-btn {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        .back-btn {
          position: absolute;
          top: 10px;
          left: 10px;
        }

        .redirect-spinner {
          @include flex-column(center);
          @include margin(bottom, 40px);

          justify-content: center;
        }
      }

      // &-backdrop {
      //   width: 100%;
      // }

      &-graybackdrop {
        background-color: rgb(0 0 0 / 60%);
      }

      &.bg-grey {
        .mat-mdc-dialog-container {
          background-color: transparent !important;
        }
      }
    }

    &-with-header {
      .header {
        background: transparent;
      }
    }
  }

  &_loader {
    @include flex-column(center);

    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: mat.m2-get-color-from-palette($secondary-map, 500);

    .circle {
      width: 16px !important;
      height: 16px !important;
      background: mat.m2-get-color-from-palette($primary-map, 500) !important;
    }
  }
}

hr {
  background-color: mat.m2-get-color-from-palette($secondary-map, 200) !important;
  height: 1px;
  border: none;
  margin: 0;
  width: 100%;
}

.unlink-fi-dialog,
.menu_dialog,
.content_main-drawer-container.unlink-fi-drawer {
  .mat-mdc-dialog-container {
    overflow: visible;
  }

  .menu__content {
    .confirm-icon {
      color: #1f1f1f;
      font-weight: 600;
    }

    .confirm-title {
      color: #1f1f1f;
      font-size: 16px;
      line-height: 120%;
      font-weight: 600;
    }

    .confirm-subtitle {
      color: #2f2f2f;
      font-size: 14px;
      line-height: 120%;
    }
  }

  .mat-mdc-dialog-content {
    overflow: visible;

    // mat-icon {
    //   height: 72px;
    //   width: 72px;
    // }
  }

  .logo {
    height: 72px;
    width: 72px;
  }
}

.float-right {
  float: right;
}

.mat-button-toggle-group {
  height: 21px;
  width: 49px;
  align-items: center;
}

.mat-button-toggle,
.mat-button-toggle-label-content {
  line-height: 20px;
  width: 25px;
  font-size: 12px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 !important;
}

.mat-button-toggle-group-appearance-standard {
  border-radius: 20px !important;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none !important;
}

.mat-button-toggle-checked {
  background: #696969;
  color: #fff !important;
  border-radius: 20px !important;
}

.mat-button-toggle .mat-pseudo-checkbox {
  margin-right: 12px;
  display: none;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: #696969 !important;
}

.lang-toggle {
  position: relative;
  float: right;
}

.lang-switch {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 16px;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: right;
  box-shadow: 0 0 5.6px 0 rgb(0 0 0 / 7%);
  padding: 0;
  border: none;
}

.lang-not-selected {
  padding: 1px;
}

/* The slider */
.lang-slider {
  width: 30px;
  height: 20px;
  position: absolute;
  left: calc(50% - 20px / 2 - 16px);
  top: calc(50% - 20px / 2);
  border-radius: 20px;
  background: #696969;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  cursor: pointer;
}

.fr .lang-slider {
  left: calc(50% - 20px / 2 + 6px);
  top: calc(50% - 20px / 2);
}

.fr.lang-switch {
  justify-content: left;
}

.continue_message {
  color: $color-black-light;

  a {
    color: $color-link;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.mat-mdc-dialog-surface {
  padding: 8px;
}

// @media only screen and (max-width: 800px) {
//   .content_overlay-full .mat-mdc-dialog-container {
//     width: 500px;
//     min-width: 500px !important;
//     margin: 0 auto;
//   }
// }

// @media only screen and (max-width: 575px) {
//   .content_overlay-full .mat-mdc-dialog-container {
//     width: 375px;
//     min-width: 375px !important;
//     margin: 0 auto;
//   }
// }

// .mat-mdc-dialog-container {
//   .mdc-dialog__content {
//     color: #000000de !important;
//     padding: 0 24px !important;
//   }
// }

.mdc-dialog__surface,
.mdc-dialog__content {
  overflow: visible !important;
}

.signout-prompt-container {
  padding: 24px 40px;
  display: flex;
  flex-direction: column;

  .btn-row {
    display: flex;
    gap: 20px;

    button {
      width: auto;
      padding: 0 40px;
    }
  }

  .btn-col {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;

    button {
      width: auto;
      padding: 0 40px;
    }
  }

  &.drawer {
    padding: 0;

    .btn-row {
      align-self: center;
      gap: 8px;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}

.fi-selection-username-container {
  padding: 12px 16px;
  border: 1px solid mat.m2-get-color-from-palette($secondary-map, 200) !important;
  background: transparent;
  border-radius: 8px;
  justify-content: space-between;
  gap: 20px;
  box-sizing: border-box;
}

.konek_gif_logo {
  width: 280px;
}

.fi-redirect {
  .fi-logo,
  svg {
    width: 55px;
    height: 55px;
  }

  mat-divider {
    height: 55px;
    margin: 0 24px;
    background-color: $color-divider;
    border-right-width: 2px;
  }
}

.sticky-footer {
  height: 153px;
  position: fixed;
  bottom: 0;
  background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, #fff 90.96%);
  backdrop-filter: blur(0.5px);

  button {
    @include responsive-width;

    margin-top: 45px !important;
  }
}

.privacy-page {
  margin-bottom: 153px;
}

.onboarding-start-page-container {
  .subtitle-1 {
    font-weight: 400 !important;
    color: #000;
  }

  .checkout-onboarding-subtitle img {
    width: 30px;
    display: flex;
    align-items: center;
    flex: 0 0 30px;
    margin-right: 16px;
  }
}

.fi-redirect .fi-row {
  gap: 16px;
}

.link-another-bank-header {
  .wallet-back-btn {
    align-self: flex-start;
    padding: 12px 0 !important;
    justify-content: start;
  }
}

.link-bank-btn-container {
  display: flex;
  flex-direction: column;
}

.info-list-border {
  border-radius: 8px;
  border: 1px solid #f4f4f4;
}

// PILOT STYLES START
.pilot-content {
  @include responsive-width;
}
pbb-pilot-header {
  display: block;
  @include responsive-width;
}

pbb-pilot-footer {
  display: block;
  @include responsive-width;
}

// PILOT STYLES END
.menu_dialog.delete-trusted {
  button.mdc-button {
    max-width: 100px !important;
  }

  mat-card-content {
    .trusted-device-details {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }
}

.content_main-drawer-container .delete-trusted {
  width: 100%;

  mat-card-content {
    .trusted-device-details {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }
}