@import "./variables";

// initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field .mat-mdc-floating-label {
  top: 0 !important;
  display: block;
  height: auto !important;
  left: -12px !important;
  color: $color-input-label !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px $color-input-bg inset !important;
}

input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px $color-input-focused-bg inset !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mat-mdc-form-field-flex:hover {
  border: 2px solid $color-input-hover-border;

  .mat-mdc-floating-label {
    color: $color-input-hover-label !important;
  }
}

.mdc-text-field--filled.mdc-text-field--focused .mat-mdc-form-field-flex {
  border: 2px solid $color-input-focused-border !important;
  background-color: $color-input-focused-bg !important;

  .mat-mdc-floating-label {
    color: $color-input-focused-label !important;
  }
}

// background color on hover and focus
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-text-field {
  overflow: visible !important;

  mat-icon {
    font-size: 20px;
  }
}

form {
  .pbb-invalid {
    color: $color-warn;
  }

  .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding-top: 3.2px;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-form-field-appearance-outline .mat-mdc-text-field--outlined {
    background-color: $color-warn-light;
    border-radius: 5px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    padding: 0 !important;
  }

  .mat-form-field-appearance-fill .mat-mdc-form-field-flex {
    height: 44px;
    padding: 0 12px;
    background-color: $color-input-bg !important;
  }

  .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
    height: 44px;
    padding: 0 12px;
    background-color: $color-input-bg !important;
  }

  .mat-mdc-form-field-suffix {
    margin-left: 5px;
  }

  .mat-mdc-form-field-required-marker {
    color: $color-error !important;
  }

  .form-field-helper {
    font-size: 14px;
    display: flex;
  }

  .with-suffix {
    .mat-mdc-form-field-infix {
      display: flex;
    }
  }
}

mat-form-field {
  display: block !important;
  margin: 16px 0;

  &.mat-form-field-appearance-fill {
    .mat-mdc-form-field-infix {
      border: none;
      padding: 0.65em 0 1.75em !important;
    }

    .mat-mdc-form-field-flex {
      border-radius: 4px !important;
      border: 2px solid rgb(0 0 0 / 1%);
      height: 44px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .mat-mdc-floating-label {
    color: $color-input-label !important;
  }

  .mdc-text-field__input {
    color: $color-input-text !important;
  }

  &.ng-touched.ng-invalid,
  &.ng-dirty.ng-invalid {
    .mat-mdc-form-field-flex {
      border: 2px solid $color-error !important;
      background-color: $color-input-error-bg !important;

      .mat-mdc-floating-label {
        color: $color-error !important;
      }
    }
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    margin-top: 16px;
    padding-bottom: 0;
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  input,
  textarea {
    &.ng-dirty.ng-invalid {
      background-image: url("/assets/images/form-error.svg");
      background-repeat: no-repeat;
      background-position: right 12px center;
    }
  }

  // success
  pbb-form-field-success {
    color: #075559;
    position: relative;
    display: contents;

    mat-icon {
      position: absolute;
      right: 0;
      top: 12px;
    }

    span {
      position: relative;
      top: 20px;
      left: -12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.8px;
    }
  }
}

.privacy-check {
  padding: 10px 0;

  .privacy-link {
    text-decoration: none !important;
    display: flex;
    align-items: center;

    .checkbox {
      margin: 0;
      height: 16px;
      width: 16px;
      line-height: 16.8px;
    }
  }
}

#personal-details-form-email-exists-helper {
  color: #CA0000;
}