@import "./variables";

.mat-icon {
  overflow: visible !important;
  z-index: 1;
}

.icon-sm {
  font-size: $icon-sm !important;
  width: $icon-sm !important;
  height: $icon-sm !important;
  line-height: $icon-sm !important;

  svg,
  img {
    width: $icon-sm;
    height: $icon-sm;
  }
}

.icon-smd {
  font-size: $icon-smd !important;
  width: $icon-smd !important;
  height: $icon-smd !important;
  line-height: $icon-smd !important;

  svg,
  img {
    width: $icon-smd;
    height: $icon-smd;
  }
}

.icon-md {
  font-size: $icon-md !important;
  width: $icon-md !important;
  height: $icon-md !important;

  svg,
  img {
    width: $icon-md;
    height: $icon-md;
  }
}

.icon-mdl {
  font-size: $icon-mdl !important;
  width: $icon-mdl !important;
  height: $icon-mdl !important;

  svg,
  img {
    width: $icon-mdl;
    height: $icon-mdl;
  }
}

.icon-lxg {
  font-size: $icon-lxg !important;
  width: $icon-lxg !important;
  height: $icon-lxg !important;

  svg,
  img {
    width: $icon-lxg;
    height: $icon-lxg;
  }
}

.icon-input {
  font-size: $icon-input !important;
  width: $icon-input !important;
  height: $icon-input !important;

  svg,
  img {
    width: $icon-input;
    height: $icon-input;
  }
}

.icon-lg {
  font-size: $icon-lg !important;
  width: $icon-lg !important;
  height: $icon-lg !important;

  svg,
  img {
    width: $icon-lg;
    height: $icon-lg;
  }
}

.icon-xlg {
  font-size: $icon-xlg !important;
  width: $icon-xlg !important;
  height: $icon-xlg !important;

  svg,
  img {
    width: $icon-xlg;
    height: $icon-xlg;
  }
}

pbb-svg-icon.wedauthn-icon {
  svg {
    height: 24px !important;
    width: 24px !important;
  }
}
