.cookie-manage {
  .mat-expansion-panel {
    border: none;
    box-shadow: none !important;

    &-header {
      padding: 16px !important;
      height: 32px !important;
      line-height: 0px;
      border-radius: 8px !important;
      align-items: unset;

      .mat-expansion-indicator {
        align-self: center;
      }

      &.mat-expanded {
        height: 32px;
      }

      &-description {
        justify-content: flex-end;

        .mat-checkbox-inner-container {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}

.privacy-page {
  .mat-accordion {
    .mat-expansion-panel {
      border: none;
      box-shadow: none !important;

      &-header {
        padding: 0 !important;
        height: 24px !important;

        &:hover {
          background: transparent !important;
        }

        mat-panel-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 120%;
        }

        .mat-expansion-indicator {
          margin: 6px;
        }
      }

      &-description {
        justify-content: flex-end;

        .mat-checkbox-inner-container {
          height: 24px;
          width: 24px;
        }
      }

      &-body {
        padding: 0;
      }
    }
  }
}
