@use "@angular/material" as mat;
@import "./mixins/margin";

.mt-48 {
  @include margin(top, 48px);
}

.mt-28 {
  @include margin(top, 28px);
}

.mb-32 {
  @include margin(bottom, 32px);
}

.mb-48 {
  @include margin(bottom, 48px);
}

.mb-45 {
  @include margin(bottom, 45px);
}

.mr-12 {
  @include margin(right, 12px);
}

.mt-32 {
  @include margin(top, 32px);
}

.mt-40 {
  @include margin(top, 40px);
}

.mt-4 {
  @include margin(top, 4px);
}

.mt-10 {
  @include margin(top, 10px);
}

.mr-4 {
  @include margin(right, 4px);
}

.mb-40 {
  @include margin(bottom, 40px);
}

.mr-55 {
  @include margin(right, 55px);
}

.mt-24 {
  @include margin(top, 24px);
}

.mt-20 {
  @include margin(top, 20px);
}

.mb-24 {
  @include margin(bottom, 24px);
}

.mt-16 {
  @include margin(top, 16px);
}

.mb-16 {
  @include margin(bottom, 16px);
}

.mb-46 {
  @include margin(bottom, 46px);
}

.mb-4 {
  @include margin(bottom, 4px);
}

.mb-2 {
  @include margin(bottom, 2px);
}

.mb-12 {
  @include margin(bottom, 12px);
}

.mb-53 {
  @include margin(bottom, 53px);
}

.mt-12 {
  @include margin(top, 12px);
}

.mt-11 {
  @include margin(top, 11px);
}

.mb-8 {
  @include margin(bottom, 8px);
}

.mt-8 {
  @include margin(top, 8px);
}

.mt-29 {
  @include margin(top, 29px);
}

.mb-9 {
  @include margin(bottom, 9px);
}

.mb-64 {
  @include margin(bottom, 64px);
}

.ml-5 {
  @include margin(left, 5px);
}

.ml-8 {
  @include margin(left, 8px);
}

.ml-12 {
  @include margin(left, 12px);
}

.ml-32 {
  @include margin(left, 32px);
}

.ml-16 {
  @include margin(left, 16px);
}

.ml-24 {
  @include margin(left, 24px);
}

.mr-8 {
  @include margin(right, 8px);
}

.mb-38 {
  @include margin(bottom, 38px);
}

.mr-15 {
  @include margin(right, 15px);
}

.ml-15 {
  @include margin(left, 15px);
}

.mr-16 {
  @include margin(right, 16px);
}

.mr-24 {
  @include margin(right, 24px);
}

.mr-40 {
  @include margin(right, 40px);
}

.mr-0 {
  @include margin(right, 0);
}

.mt-60 {
  @include margin(top, 60px);
}

.mt-92 {
  @include margin(top, 92px);
}

.mt-200 {
  @include margin(top, 200px);
}

.p-4 {
  padding: 4px;
}

/* color filters */
.filter {
  &-white {
    filter: brightness(0) invert(1);
  }

  &-teal {
    filter: invert(51%) sepia(29%) saturate(3022%) hue-rotate(139deg) brightness(87%) contrast(98%);
  }
}

/* layout */
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
  justify-content: left;
}

.text-underlined {
  text-decoration: underline !important;
}

.block {
  display: block;
  width: 100%;
}

.flex {
  &-col {
    @include flex-column(center);
  }

  &-row {
    @include flex-row(center);

    justify-content: space-between;
  }

  &-row-middle {
    @include flex-row(center);
  }

  &-inline {
    @include flex-inline(center);
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-jc-none {
    justify-content: normal;
  }

  &-jc-space {
    justify-content: space-between;
  }

  &-left {
    @include flex-row(center);

    justify-content: flex-start !important;
  }

  &-end {
    @include flex-row(center);

    justify-content: flex-end;
  }

  &-around {
    justify-content: space-around;
  }

  &-center {
    @include flex-column(center);

    justify-content: center;
  }

  &-row-center {
    @include flex-row(center);

    align-items: flex-start !important;
    justify-content: center;
  }

  &-start {
    align-items: flex-start !important;
  }

  &-baseline {
    @include flex-row(baseline);
  }

  &-full-width {
    width: 100%;
  }

  &-align-start {
    align-self: flex-start !important;
  }
}

.bg {
  &-transparent {
    background-color: transparent !important;
    background-image: none;
  }

  &-grey {
    background-color: $color-grey-light !important;
    background-image: none;
  }
}

.auto-height {
  height: auto !important;
}

// TO be Updated when we have separate snackbar component
.red-snackbar {
  background: #b00020 !important;
  color: white !important;
}

.prewrap {
  white-space: pre-wrap;
}

.preline {
  white-space: pre-line;
}

.pre {
  white-space: pre;
}

.break-word {
  overflow-wrap: break-word;
}

.break-anywhere {
  overflow-wrap: anywhere;
}

.text-disabled {
  color: #b3b3b3 !important;
}

.text-divider {
  width: 100%;
  height: 9px;
  border-bottom: 1px solid mat.m2-get-color-from-palette($secondary-map, 200);
  text-align: center;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: $color-notice-light;
    padding: 0 78px;
    background: #fff;
  }
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.mandatory-indicator {
  color: #ca0000;
}

.warn-indicator {
  color: #ca0000 !important;
}

.required-label {
  background-color: #fff6e0;
  padding: 2px 6px;
  color: #7e5800;
  font-size: 14px;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}

.text-bold {
  font-weight: 600 !important;
}
