@use "@angular/material" as mat;
@import "./theme/palletes";

$header-height: 56px;
$content-width-sm: 375px;
$content-width-md: 500px;
$content-width-lg: 680px;
$icon-xlg: 136px;
$icon-lxg: 106px;
$icon-lg: 48px;
$icon-md: 32px;
$icon-mdl: 24px;
$icon-smd: 16px;
$icon-sm: 12px;
$icon-img: 36px;
$icon-input: 20px;

// breakpoints
$breakpoint-large: 996px;
$breakpoint-tablet: 800px;
$breakpoint-mobile: 575px;

// color:
$color-black: #000;
$color-success: #03a79a;
$color-teal: #03a79a;
$color-pink: #ca27ca;
$color-grey-dark: #333;
$color-grey: #999;
$color-grey-light: #fbfbfb;
$color-primary: mat.m2-get-color-from-palette($primary-map, 500);
$color-font-on-primary: mat.m2-get-color-from-palette($primary-map, contrast(500));
$color-blue-light: mat.m2-get-color-from-palette($primary-map, 400);
$color-notice-dark: #1f1f1f;
$color-dark-charcoal: #2f2f2f;
$color-notice-light: #666;
$color-carousal-active-grey: #afafaf;
$color-carousal-grey: #c4c4c4;
$color-warn: #ffab00;
$color-error: #ca0000;
$color-warn-light: #fddfdf;
$color-hr: #e6e6e6;
$color-error-bg: $color-warn-light;
$color-info-bg: #e1eaeb;
$color-info: #075559;
$color-warn-bg: #fff7e6;
$color-fushsia: #ffecff;
$color-list-item-bg: #fbfbfb;
$color-fi-btn-disabled: #f7f7f7;
$color-white: #fff;
$color-tooltip: #333;
$color-link: #3148bb;
$color-black-light: #666;
$color-grey-subtitle: #696969;
$color-input-label: #696969;
$color-input-bg: #ececec;
$color-input-hover-border: #2f2f2f;
$color-input-hover-label: #666;
$color-input-focused-bg: #edf0fe;
$color-input-focused-border: #3148bb;
$color-input-focused-label: #1f1f1f;
$color-input-text: #1f1f1f;
$color-input-error-bg: #fddfdf;
$color-divider: #e7e7e7;
$color-grey-hover: #eaeaea;
$color-grey-active: #b5b5b5;
$color-warn-2: #7e5800;
$color-warn-bg-2: #fff6e0;
$color-body-bg: #fcfcfc;
$color-radio-btn-border: #0000001f;

// sizing
$button-border-radius: 8px;
$radio-btn-border-radius: 8px;
$spacing-row-md: 16px;
