.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #1976d2;
  color: white;
  font-weight: 600;
  padding: 0 20px;
}

.mat-toolbar {
  background: none;
  &.warning-toolbar {
    background-color: #fff7e6;
    border-radius: 12px;

    .mat-icon {
      color: #ffab00;
    }
  }
}
