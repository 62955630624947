.splash {
  .fi-row {
    column-gap: 31px;
    justify-content: center;

    .fi-logo,
    svg {
      width: 100px;
    }

    .lock-icon {
      width: 24px;
      height: 33px;
    }
  }

  .confetti {
    width: 500px;
    height: 666px;
    max-height: max-content !important;
    position: absolute;
  }
}
