@use "@angular/material" as mat;
@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

$konek-typography: mat.m2-define-typography-config(
  $font-family: urbanist,
  $headline-1: mat.m2-define-typography-level(32px, 38.4px, 600),
  $headline-4: mat.m2-define-typography-level(14px, 16.8px, 600),
  $headline-5: mat.m2-define-typography-level(24px, 28.8px, 600),
  $headline-6: mat.m2-define-typography-level(20px, 24px, 600),
  $subtitle-1: mat.m2-define-typography-level(20px, 24px, 600),
  $subtitle-2: mat.m2-define-typography-level(20px, 24px, 600),
  $body-1: mat.m2-define-typography-level(16px, 19.2px, 400),
  $body-2: mat.m2-define-typography-level(14px, 16.8px, 400),
  $caption: mat.m2-define-typography-level(12px, 14.4px, 400),
  $button: mat.m2-define-typography-level(16px, 19.2px, 700),
);

@include mat.all-component-typographies($konek-typography);

.mat-typography {
  a {
    color: $color-black;
    cursor: pointer;
  }

  a.cookie-terms {
    color: $color-pink;
  }

  a.signup {
    color: $color-pink;
    text-decoration: none;
    font-weight: 600;
  }

  a.switch,
  a.login {
    color: $color-pink;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
  }

  a.privacy-link {
    text-decoration: none;
  }

  footer a {
    text-decoration-line: none;
  }

  .display-heading {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.5px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: -0.5px;
  }

  .mat-headline-4,
  .mat-headline-5,
  .mat-body-1,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    color: mat.m2-get-contrast-color-from-palette($secondary-map, 100);
  }

  .text {
    color: mat.m2-get-contrast-color-from-palette($secondary-map, 100);

    &-black {
      color: mat.m2-get-contrast-color-from-palette($secondary-map, 100);
    }

    &-dark {
      color: #000;
    }

    &-grey {
      color: mat.m2-get-color-from-palette($secondary-map, 800);
    }

    &-grey-light {
      color: mat.m2-get-contrast-color-from-palette($secondary-map, 300);
    }

    &-grey-dark {
      color: $color-grey-dark;
    }

    &-grey-subtitle {
      color: $color-grey-subtitle;
    }

    &-teal {
      color: $color-teal;
    }

    &-success {
      color: $color-success;
    }

    &-warn {
      color: $color-warn;
    }

    &-info {
      color: $color-info;
    }

    &-desc {
      color: $color-notice-light;
    }

    &-link {
      color: $color-link;
    }

    &-dark-charcoal {
      color: $color-dark-charcoal;
    }

    &-primary {
      color: mat.m2-get-color-from-palette($primary-map, 500);
    }

    &-error {
      color: $color-error;
    }

    &-ellipsis {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-gray-active {
      color: $color-grey-active;
    }
  }

  .main-title {

  }

  .mat-small {
    line-height: normal;
  }

  .first-time-heading {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  .first-time-container {
    display: inline-flex;
    margin: 16px 0;
    align-items: center;
  }

  .sign-in-or-container {
    display: flex;
    margin: 24px 0;
    align-items: center;
    width: 100%;
  }

  .first-time-text {
    white-space: nowrap;
    margin: 0 8px;
  }

  .sign-in-or-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 8px 0;
    color: #666;
    white-space: nowrap;
    margin: 0 10px;
  }

  .first-time-hr,
  .sign-in-or-hr {
    width: 100%;
    height: 1px;
    background: $color-divider;
  }

  a[class*="_blank"]::after {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==");
    margin: 0 3px 0 5px;
  }

  .button-1 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
  }

  .button-2 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
  }

  .subtitle-1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0;
  }

  .subtitle-2 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  .subtitle-account {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .subtitle-3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .subtitle-4 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
  }

  .subtitle-medium {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  .heading-3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.5px;
  }

  .total-item-title {
    font-size: 15px;
    font-weight: 400;
    color: $color-grey-subtitle;
  }

  .total-item-amount {
    font-size: 15px;
    font-weight: 400;
  }

  .heading-4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.5px;
    margin-bottom: 8px;
  }

  .heading-splash {
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.5px;
  }

  .link-text {
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;

    pbb-svg-icon {
      vertical-align: middle;
    }
  }

  .link-text-alt {
    font-weight: 400;
    font-size: 16px;
    text-decoration: underline;

    pbb-svg-icon {
      vertical-align: middle;
    }
  }

  .verified-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    text-decoration: none;
  }

  .mixed-typography-1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  .link-text-footer {
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    text-decoration: none;
  }

  .link-cancel-return-to-fi {
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.2px;
    text-decoration: none;
  }

  .link-no-decoration {
    text-decoration: none;
  }

  .timeout-timer {
    color: $color-black;
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.5px;
  }

  .button-subtitle {
    color: $color-grey-subtitle;
    font-size: 14px;
    font-weight: 400;
  }

  small {
    color: $color-grey-subtitle;
    font-size: 14px;
  }
}
