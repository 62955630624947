@use "@angular/material" as mat;

pbb-nav-list .mdc-list-item__content {
  background-color: #fff !important;
}

.mat-mdc-list-item.mdc-list-item {
  padding: 0;
}

pbb-address-list {
  .mat-mdc-list {
    &-item {
      height: auto !important;
      padding: 16px !important;
      cursor: pointer;
      border-radius: 8px !important;
      border: 1px solid #f4f4f4 !important;
      background: #fff !important;

      @include margin(bottom, 24px);

      &-unscoped-content {
        .text-content {
          overflow: hidden;

          div {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
          }
        }
      }

      &.selected-address {
        border: solid 2px mat.m2-get-color-from-palette($secondary-alternate-map, 300);
        border-radius: 8px;
        background-color: mat.m2-get-color-from-palette($secondary-alternate-map, 400) !important;
        color: mat.m2-get-color-from-palette($secondary-alternate-map, 500) !important;
        box-sizing: border-box;
      }
    }

    .address-list-item__recipient_name {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
    }

    .address-list-item__recipient_details {
      color: #1f1f1f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }

  &.user-portal-address-list {
    .mat-mdc-list {
      margin: 0;

      &-item {
        border: none;

        @include margin(bottom, 0);

        height: auto !important;

        &:not(:last-child) {
          border-bottom: solid 1px mat.m2-get-color-from-palette($secondary-map, 200);
        }

        &-unscoped-content {
          flex: 1;
          padding: 0 16px !important;

          .mat-subtitle-2,
          .mat-mdc-chip {
            @include margin(bottom, 3px);
          }
        }
      }
    }
  }
}

pbb-trusted-list {
  .mat-mdc-list {
    &-item {
      border:solid 1px $color-radio-btn-border;

      height: auto !important;

      &:last-child {
        margin-bottom: 0px !important;
      }

      .mdc-list-item__secondary-text::before {
        display: none !important;
      }

      &-unscoped-content {
        flex: 1;
        padding: 16px 16px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-detail {
          @include flex-column(flex-start);

          justify-content: flex-start;
        }
      }
    }
  }
}

.fi-list {
  .mat-mdc-list {
    display: flex;
    flex-flow: wrap;

    &-base {
      padding: 0 !important;
    }

    &-item {
      background-color: $color-white;

      &-unscoped-content {
        flex: 1;
        padding: 0 !important;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      height: 64px !important;
      padding: 0;
    }
  }
}

.check-list {
  @include margin(top, 16px);
  @include margin(bottom, 48px);

  .mat-mdc-list {
    &-base {
      padding: 0 !important;
    }

    &-item {
      height: auto !important;

      .mdc-list-item__primary-text {
        font-size: 14px !important;
        white-space: normal;
      }

      &-unscoped-content {
        flex: 1;
        padding: 0 !important;
        justify-content: flex-start;
        gap: 4px;

        @include margin(bottom, 4px);

        >.mat-icon {
          width: 16px !important;
          height: 16px !important;
          padding: 0 !important;
        }
      }
    }

    mat-list-item + mat-list-item {
      margin-top: $spacing-row-md;
    }
  }
}

.bullet-list {
  list-style: disc;
  padding: 0 30px;
}

.payment-method-container {
  .payment-method-container__fi-account {
    font-size: 14px;

    .payment-method-label {
      display: flex;
      flex-direction: column;
    }

    .payment-method-display-name {
      font-size: 16px;
      font-weight: 600;
      color: $color-black;
      overflow: visible !important;
      white-space: normal !important;
    }

    .payment-method-logo {
      width: 50px;
      display: flex;

      // justify-content: center;
      align-items: center;
      flex: 0 0 50px;
    }

    .payment-method-type {
      color: $color-input-label;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      overflow: visible !important;
      white-space: normal !important;
    }

    .mdc-list-item__primary-text {
      white-space: nowrap !important;
    }
  }

  &__fi {
    @include flex-row(center);

    justify-content: space-between;
    padding-right: 12px;
  }

  .payment-method-container__fi-account-list {
    margin: 0;
  }

  mat-list-item.payment-method-container__fi-account-list-item {
    @include flex-row(center);
  }

  mat-list-item {
    border-radius: 8px;
    padding: 12px !important;
    height: auto !important;
    background-color: #fbfbfb;
    border: solid 2px #fbfbfb;
    margin-bottom: 10px;
  }
}

.mat-mdc-list-item-unscoped-content {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

pbb-nav-list {
  button {
    border: none !important;
    text-align: left;
  }

  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    flex-basis: 36px;
    border-radius: 6px;
  }

  .mat-mdc-nav-list {
    .mat-mdc-list-item {
      margin: 0;
      height: auto !important;
      padding: 0;

      .mdc-list-item__content {
        background-color: mat.m2-get-color-from-palette($secondary-map, 700);

        .mat-mdc-list-item-unscoped-content {
          flex: 1;

          @include flex-row(center);

          padding: 16px !important;
          border-radius: 8px;
          justify-content: space-between;

          .mat-icon {
            line-height: 100%;
            color: #fff !important;
          }

          .mat-icon-right {
            line-height: 100%;
            color: #b9b9b9 !important;
          }
        }

        &__title {
          @include flex-column(flex-start);

          height: 38px;
          flex: 1 1 auto;
          order: 2;
        }

        &__prefix {
          order: 1;
        }

        &__suffix {
          order: 3;
        }
      }
    }
  }

  hr {
    width: auto;
  }
}

.cookies-list {
  width: 100%;
  overflow-x: auto;
  display: block;

  .mat-mdc-cell,
  .mat-header-cell {
    padding: 5px !important;
    min-width: none;
  }
}

.partners-list {
  &.mat-mdc-list {
    gap: 13px;
    height: 24px !important;
    padding: 0;

    mat-list-item {
      height: min-content;
    }
  }
}

.info-list {
  width: 100%;

  mat-list-item {
    height: max-content !important;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.privacy-list {
  list-style-type: disc;

  // list-style-position: outside;
  margin-left: 14px;

  li {
    &:last-child {
      margin-bottom: 24px;
    }
  }
}
